<template>
  <b-sidebar
    id="add-new-group-code-sidebar"
    :visible="isAddNewGroupCodeSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-group-code-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0">{{ button_text }} 集團代號</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- Body -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <b-overlay
          :show="loading"
          spinner-variant="primary"
          spinner-type="grow"
          spinner-small
          rounded="sm"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <!-- 集團代號-->
            <validation-provider
              #default="validationContext"
              name="集團代號"
              rules="required|max:6"
            >
              <b-form-group label="集團代號" label-for="group-code">
                <b-form-input
                  id="group-code"
                  v-model="groupcodeData.group_code"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="集團代號"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- 集團-->
            <validation-provider
              #default="validationContext"
              name="集團"
              rules="required"
            >
              <b-form-group label="集團" label-for="name-of-group">
                <b-form-input
                  id="name-of-group"
                  v-model="groupcodeData.name_of_group"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="集團"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <b-form-group label="描述" label-for="description">
              <quill-editor v-model="groupcodeData.description" />
            </b-form-group>

            <!-- Form Actions -->

            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                {{ button_text }}
              </b-button>

              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                取消
              </b-button>
            </div>
          </b-form>
        </b-overlay>
      </validation-observer>
    </template></b-sidebar
  >
</template>
   
  </b-sidebar>
  
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormTextarea,
  BFormCheckbox,
  BOverlay,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref, watch } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import { max } from "@/libs/validations.js";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import store from "@/store";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    BFormCheckbox,
    vSelect,
    BOverlay,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    quillEditor,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewGroupCodeSidebarActive",
    event: "update:is-add-new-group-code-sidebar-active",
  },
  props: {
    isAddNewGroupCodeSidebarActive: {
      type: Boolean,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      max,
    };
  },
  setup(props, { emit }) {
    const loading = ref(false);

    const blankGroupCodeData = {
      id: 0,
      group_code: "",
      name_of_group: "",
      description: "",
    };
    const button_text = ref("新增");

    const groupcodeData = ref(JSON.parse(JSON.stringify(blankGroupCodeData)));

    const resetgroupcodeData = () => {
      groupcodeData.value = JSON.parse(JSON.stringify(blankGroupCodeData));
    };

    const toast = useToast();

    watch(
      () => [props.id, props.isAddNewGroupCodeSidebarActive],
      () => {
        if (props.id > 0 && props.isAddNewGroupCodeSidebarActive) {
          groupcodeData.value.id = props.data.id;
          groupcodeData.value.group_code = props.data.group_code;
          groupcodeData.value.name_of_group = props.data.name_of_group;
          groupcodeData.value.description = props.data.description;
          if (groupcodeData.value.id > 0) {
            button_text.value = "修改";
          } else {
            button_text.value = "新增";
          }
        } else {
          button_text.value = "新增";
        }
      }
    );

    const onSubmit = () => {
      loading.value = true;
      store
        .dispatch("client-groupcode/addGroupCode", groupcodeData.value)
        .then((response) => {
          loading.value = false;
          emit("update:is-add-new-group-code-sidebar-active", false);
          emit("refetch-data");
          toast({
            component: ToastificationContent,
            props: {
              title: "Success",
              text: response.data.message,
              icon: "CheckIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          loading.value = false;
          toast({
            component: ToastificationContent,
            props: {
              title: "Fail",
              text: error.response.data.message,
              icon: "HeartIcon",
              variant: "danger",
            },
          });
        });
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetgroupcodeData);

    return {
      groupcodeData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      button_text,
      loading,
    };
  },
};
</script>

</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
#add-new-group-code-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
.ql-editor{
	height:300px;
}
</style>